.displayed-propositions-container {
    box-sizing: border-box;
    background: #fff;
    padding: 5rem 8rem 20rem 8rem;
    height: 100vh;
    max-width: 40rem;
    overflow: scroll;
    touch-action: pan-y;
}
@media (max-width: 900px) {
    .displayed-propositions-container {
        max-width: 36rem;
        padding: 5rem 6rem 20rem 6rem;
    }
}
@media (max-width: 600px) {
    .displayed-propositions-container {
        padding: 5rem 3rem 20rem 3rem;
    }
}

.displayed-propositions-expand-contract-button {
    position: absolute;
    right: 1rem;
    top: 3rem;
    background: none;
    border: none;
    color: lightgray;
    cursor: pointer;
    font-size: 1.5rem;
}
.displayed-propositions-expand-contract-button:hover {
    color: gray;
}
.displayed-propositions-expand-contract-button:focus {
    outline: 0;
}
@media (max-width: 600px) {
    .displayed-propositions-expand-contract-button {
        right: .3rem;
        top: 3rem;
    } 
}

.displayed-proposition {
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.displayed-proposition-main, .displayed-proposition-earlier-siblings {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.displayed-proposition::after {
    content: "";
    width: 0;
    height: 3rem;
    margin: 2rem auto 0 auto;
    display: block;
    border-left: 1px solid #000;
}

.displayed-proposition:last-of-type::after {
    display: none;
}

.displayed-proposition__text {
    text-align: justify;

}

.displayed-proposition__number {
    font-size: 1rem;
    text-align: center;
}


.displayed-propositions-container__link-container {
    display: flex;
}
.displayed-propositions-container__link {
    color: var(--light-color)
}
.displayed-propositions-container__link:hover {
    color: var(--highlight-color)
}
.displayed-propositions-container__link--previous {
}

.displayed-propositions-container__link--next {
    margin-left: auto;
}

.footnote {
    text-align: justify;
    align-self: flex-end;
    font-size: .8rem;
    width: 60%;
    padding-top: .6rem;
    border-top: 1px solid var(--main-color)
}
.footnote::before {
    content: "*";
    margin-right: .3rem;
}

.displayed-proposition-earlier-sibling {
    color: grey;
    margin-bottom: 3rem;
}

.displayed-proposition-earlier-sibling .displayed-proposition__number a {
    color: grey;
    font-weight: normal;
}