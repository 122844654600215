.rootNodeContainer {
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
}

/* the viewContainer is used as a "viewport", scrolled to center in the larger viewCanvas */
/* on screen devices we keep the canvas same as screen, so now scrolling. On smaller devices we make it larger. */
.viewCanvas {
    position: relative;
    width: 100vw;
    height: 100vh;
}
@media (max-width: 900px) {
    .viewCanvas {
        position: relative;
        width: 150vw;
        height: 120vh;
    }
}
@media (max-width: 600px) {
    .viewCanvas {
        position: relative;
        width: 200vw;
        height: 150vh;
    }
}
.viewContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100vw;
    height: 100vh;
}

.rootNodeContainer.foreground {
    z-index: 10;
}

.PropositionNode {
    color: var(--main-color);
    position: relative;
    padding-left: var(--edge-length);
    transform-origin: center left;
    transform: rotate(var(--rotation-angle));
    cursor: pointer;
}

.PropositionNode::before {
    /* the graph's edges are :before pseudoelements */
    content: "";
    position: absolute;
    right: 0;
    transform: translate(0,-50%);
    width: 100%;
    height: 1px;
    background: var(--main-color);
}

/* 
    We only show the active foreground nodes
    Otherwise we hide them, and see the background nodes behind them
    Reason is that the changing stacking context causes a flickering in the siblings of 
    the foreground. So we hide them, and show a background instead.
*/
.foreground .PropositionNode {
    visibility: hidden;
}

.foreground .PropositionNode.active {
    visibility: visible;
    color: var(--highlight-color);
    z-index: 10;
}

.PropositionNode.active::before {
    height: 3px;
    background: var(--highlight-color);
}

.rootNodeContainer > .PropositionNode {
    padding: 0 !important;
    border: none;
}

.Proposition {
    position: absolute;
    top: 50%;
    right: 0;
    transform:  translate(50%,-50%) rotate(var(--compensating-angle));
    background: #fff;
    z-index: 11;
    padding: .1rem;
}

.Proposition__number {
    margin: 0;
    padding: 0;
}

.PropositionNode__children {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    list-style: none;
}

.PropositionNode__child {
    position: absolute;
    top: 0;
    left: 0;
}

/* the below fades all nodes but the children of the selected proposition a bit*/
.background {
    transition: opacity 300ms;
}
.foreground:hover + .background {
    opacity: .3;
}
.PropositionNode.selected .PropositionNode {
    visibility: visible;
}


/* growth animation */
.PropositionNode.grow-appear {
    padding-left: 0rem;
}
/* we delay the animation based on depth level */
.PropositionNode.grow-appear-active {
    padding-left: var(--edge-length);
    transition: padding 1s calc( var(--grow-speed) * calc(var(--level) - 2ms));
    transition-timing-function: ease-out;
}
.background .PropositionNode.grow-appear .Proposition {
    visibility: hidden;
    animation: reveal calc( var(--grow-speed) * calc(var(--level) - 2ms));
    animation-fill-mode: forwards;
}
@keyframes reveal {
    99% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }