.example-appear {
    opacity: 0;
}
.example-appear-active {
    opacity: 1;
    transition: opacity 10s;
}

.Menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    position: fixed;
    top: 2rem;
    right: 0rem;
    font-size: 1.3rem;
    margin: 0rem 2rem 0 0;
    z-index: 10;
}

.Menu__group {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Menu__navigation-link {
    color: var(--light-color);
    text-decoration: none;

    margin: .2rem;
}

.Menu__navigation-link:hover {
    color: var(--highlight-color);
}

.Menu__navigation-link.active {
    color: var(--mid-color);
}

.Menu__navigation-link.disabled .Menu__icon path{
    fill: var(--light-color);
}

.Menu__icon path {
    fill: var(--mid-color);
}
.Menu__icon:hover path {
    fill: var(--highlight-color);
}

.Menu__button {
    cursor: pointer;
    color: var(--light-color);
    background: none;
    border: none;
    margin: .2rem;
}
.Menu__button:hover {
    color: var(--main-color);
}
.Menu__button:active {
    color: var(--highlight-color);
}
.Menu__button:focus {
    outline: 0;
}

.Menu__button--info {
    font-family: 'Arial';
    font-size: 1.3rem;
}

@media (max-width: 600px) {
    .Menu {
        top: 1rem;
        right: .2rem;
        margin: 0;
    }
    .Menu__navigation-link--number {
        display: none;
    }
    .Menu__navigation-link--number.active{
        display: block;
    }
}
@media (hover: none) {
    .Menu__button--fullscreen {
        display: none;
    }
}
