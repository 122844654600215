.PropositionPreview {
    position: fixed;
    top: 0;
    left: 0;
    width: 20rem;
    padding: 1.5rem 3rem;
    opacity: .25;
    z-index: 0;
}

@media (max-width: 400px) {
    .PropositionPreview {
        padding: 1rem 2rem;
        width: 15rem;
    }
}

.PropositionPreview img {
    opacity: .5;
}

.PropositionPreview--number {
    font-size: 1.5rem;
}