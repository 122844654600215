/* this prevents pinch zoom on mobile; which makes a mess of all fixed positions */
:root {
    touch-action: pan-x pan-y;
    height: 100% 
}

body {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100vw;
    box-sizing: border-box;
    margin: 0;
}

#root {
    position: relative;
    width: 100%;
}

a {
    text-decoration: none;
    color: #000;
}

.emphasis {
    font-style: italic;
}