/* Proposition parsing */

/* The original has textindented on every new line
Unfortuately the text-indent each-line flag has no support yet
I instert spans after breaks as a workaround
uncomment to insert initial tab --- but messes with centering visually 
for single line propositions
.proposition__text {
    text-indent: 30px;
} */

.indenter {
    display: inline-block;
    width: 30px;
}

.texCommand_textit {
    font-style: italic;
}

.texCommand_emph {
    font-style: italic;
}

.texCommand_EmphPart {
    font-style: italic;
}

.texCommand_enlargethispage {
    display: none;
}

.texCommand_DPtypo {
    display: none;
}

.texCommand_BookTitle {
    font-style: italic;
}

.proposition__image {
    display: block;
    width: 100%;
    margin: 30px auto;
}

.proposition__image--cube {
    width: 50%;
}

.proposition__image--brackets03-en, .proposition__image--brackets03-de {
    width: 25%;
}

.truthtable__container {
    display: flex;
    justify-content: space-evenly;
}

.truthtable {
    border-collapse: collapse;
}

.truthtable th {
    border-left: 1px solid var(--main-color);
    border-bottom: 1px solid var(--main-color);
    padding: .5rem 1rem;
}

.truthtable td {
    border-left: 1px solid var(--main-color);
    border-bottom: 1px solid var(--main-color);
    padding: .5rem 1rem;
}

.truthtable td:first-child {
    border-left: none;
}

.truthtable th:first-child {
    border-left: none;
}

.truthtable .truthtable__separator {
    height: .2rem;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid var(--main-color);
    border-left: none;
}

.truthtable .truthtable__separator--left {
    padding: 0 .1rem 0 0;
    border-bottom: none;
    border-left: 1px solid var(--main-color);
}

.truthtable .truthtable__separator--both {
    padding: 0 .1rem 0 0;
    border-bottom: none;
    border-left: 1px solid var(--main-color);
    border-right: 1px solid var(--main-color);
}

/* custom tweaks */
.table5101 {
    margin-bottom: 1rem;
}
.table5101 td:first-child{
    width: 7rem;
}
.table5101 td:last-child{
    padding-left: 1rem;
}
