.Introduction-link {
    display: block;
    width: 100vw;
}

.Introduction-container .tlp-cover {
    position: fixed;
    right: 5rem;
    top: 5rem;
    max-width: 20vw;
    z-index: -10;
}

.Introduction {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 6rem 4rem;
    text-align: justify;
    font-size: 1.4rem;
    max-width: 40rem;
}

@media (max-width: 600px) {
    .Introduction {
        padding: 2rem;
    }
}

.Introduction p:first-child::first-letter {
    font-size: 3rem;
}