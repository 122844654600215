.Modal {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 100;
}

.Modal--background {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.3);
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

.Modal--children {
    position: relative;
    z-index: 1;
}

.Modal--close-button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    background: none;
    border: none;
    color: lightgray;
    cursor: pointer;
    font-size: 1.6rem;
    transform: rotate(45deg);
    transform-origin: center;
}
.Modal--close-button:hover {
    color: gray;
}
.Modal--close-button:focus {
    outline: 0;
}
@media (max-width: 900px) {
    .Modal--close-button {
        right: 1rem
    }
}
/* shifted in devtools; not on real devices */
@media (max-width: 600px) {
    .Modal--close-button {
        right: .1rem
    }
}